<template>
  <div>
    <el-dialog title="关联产品" :visible.sync="editPage" width="70%" :close-on-press-escape="false"
      :close-on-click-modal="false">
      <!--内容-->
      <div class="">
        <!--搜索-->
        <div v-show="issearch" class="page-content-search">
          <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
            <el-row>
              <el-col :span="6">
                <el-form-item label="企业名称">
                  <el-input v-model="search.company_name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="产品名称">
                  <el-input v-model="search.product_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="page-content-search-button">
              <el-col :span="24">
                <el-form-item>
                  <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                  </el-button>
                  <el-button icon="el-icon-circle-close" size="medium" @click="handleTabsClick">清空搜索</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!--标题-->
        <el-row>
          <el-col :span="20">
            <h3></h3>
          </el-col>
          <el-col :span="4">
            <div>
              <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                @click="issearch = !issearch">隐藏搜索
              </el-button>
              <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                @click="issearch = !issearch">显示搜索
              </el-button>
            </div>
          </el-col>
        </el-row>
        <div style="height: 20px"></div>
        <el-tabs v-model="productType" @tab-click="handleTabsClick">
          <el-tab-pane label="团课" name="group"></el-tab-pane>
          <el-tab-pane label="训练营" name="camp"></el-tab-pane>
          <el-tab-pane label="私教" name="pt"></el-tab-pane>
          <el-tab-pane label="商品" name="commodity"></el-tab-pane>
          <el-tab-pane label="活动" name="activity"></el-tab-pane>
          <el-tab-pane label="赛事" name="contest"></el-tab-pane>
        </el-tabs>
        <!--列表-->
        <el-table size="medium" ref="multipleTable" @select="onselect" @select-all="onselect" border v-loading="loading"
          :data="tableData" style="width: 100%">
          <el-table-column type="selection" header-align="center" align="center" width="50">
          </el-table-column>
          <el-table-column prop="company_name" label="企业名称">
          </el-table-column>
          <el-table-column prop="store_name" label="门店名称">
          </el-table-column>
          <el-table-column prop="product_name" label="产品名称">
          </el-table-column>

          <el-table-column prop="product_name" label="产品时间">
            <template slot-scope="scope">
              <div v-if="productType === 'group' || productType === 'camp'">
                <p>开始时间：{{ scope.row.start_time }}</p>
                <p>结束时间：{{ scope.row.end_time }}</p>
              </div>
              <div v-else-if="productType === 'commodity'">
                <p>有效时间至：{{ scope.row.expiration_time }}</p>
                <p>使用时间：{{ scope.row.usage_time }}</p>
              </div>
              <div v-else-if="productType === 'activity'">
                <p>报名截止时间：{{ scope.row.signup_end_time }}</p>
              </div>
              <p v-else> / </p>
            </template>
          </el-table-column>
          <el-table-column prop="product_type" label="产品类型">
          </el-table-column>
        </el-table>
        <div style="height: 20px"></div>
        <!--分页-->
        <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
          layout="prev, pager, next, total" :current-page.sync="page" :total="count">
        </el-pagination>
      </div>
      <el-row style="margin: 20px 0px">
        <el-button size="medium" type="primary" @click="save">确 定</el-button>
        <el-button size="medium" @click="editPage = false">取 消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      editPage: false,
      loading: false,
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      pageSize: 8,
      tableData: [], // 列表内容
      allSelections: [],
      productType: "group",
    };
  },
  computed: {
    dataListSelections() {
      const selections = this.allSelections;
      const list = this.tableData;
      const result = [];

      list.forEach((item) => {
        selections.forEach((e) => {
          if (item.product_uuid === e.product_uuid) {
            result.push(item);
          }
        });
      });

      return result;
    },
  },

  watch: {
    dataListSelections: {
      handler(list) {
        if (!this.$refs.multipleTable) {
          return;
        }

        this.$nextTick(() => {
          list.forEach((item) => {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          });
        });
      },
    },
    editPage: {
      handler(v) {
        if (!v) {
          this.$emit("refreshDataList");
        }
      },
    },
  },
  methods: {
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    init(row) {
      this.info = row;
      this.editPage = true;
      const product_all = [];
      this.productType = "group";
      row.product_all.forEach((item) => {
        if (!product_all.find((e) => item.product_uuid == e.product_uuid)) {
          product_all.push(item);
        }
      });
      this.allSelections = [...product_all];
      this.handleTabsClick();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    onselect(selection) {
      this.diffselection(selection);

      let product_all = [];
      this.allSelections.forEach((e) => {
        let item = {
          product_uuid: e.product_uuid,
          product_name: e.product_name,
          type: e.type,
        };
        product_all.push(item);
      });

      this.setProduct(product_all);
    },
    setProduct(product_all) {
      let postdata = {
        api_name: "operate.tag.setproduct",
        token: this.Tool.get_l_cache("token"),
        product_all,
        id: this.info.id,
      };
      this.loading = true;

      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => { },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 获取列表
    getlistImp(search, parseData) {
      let postdata = {
        api_name: "product.activity.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list.map(parseData);
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    getlist() {
      this[`get${this.productType}List`]?.();
    },
    diffselection(selection) {
      const removeList = this.tableData.filter(
        (item) => !selection.find((e) => item.product_uuid == e.product_uuid)
      );

      // 新增
      selection.forEach((item) => {
        if (
          !this.allSelections.find((e) => item.product_uuid == e.product_uuid)
        ) {
          this.allSelections.push(item);
        }
      });

      // 删除
      removeList.forEach((item) => {
        const index = this.allSelections.findIndex(
          (e) => item.product_uuid == e.product_uuid
        );
        if (index != -1) {
          this.allSelections.splice(index, 1);
        }
      });
    },
    save() {
      this.editPage = false;
    },
    getgroupList() {
      this.getlistImp(
        {
          api_name: "lessonschedule.group.getlist",
          title: this.search.product_name,
          company_name: this.search.company_name,
        },
        (item) => {
          return {
            ...item,
            product_uuid: item.good_uuid,
            product_name: item.title,
            company_name: item.company_name,
            store_name: item.store_name,
            product_type: "团课",
            type: "group",
          };
        }
      );
    },
    getcampList() {
      this.getlistImp(
        {
          api_name: "lessonschedule.camp.getlist",
          title: this.search.product_name,
          company_name: this.search.company_name,
        },
        (item) => {
          return {
            ...item,
            product_uuid: item.good_uuid,
            product_name: item.title,
            company_name: item.company_name,
            store_name: item.store_name,
            product_type: "训练营",
            type: "camp",
          };
        }
      );
    },
    getptList() {
      this.getlistImp(
        {
          api_name: "lessonschedule.pt.getlist",
          title: this.search.product_name,
          company_name: this.search.company_name,
        },
        (item) => {
          return {
            ...item,
            product_uuid: item.good_uuid,
            product_name: item.title,
            company_name: item.company_name,
            store_name:
              item.store_uuid_all?.map((item) => item.store_name).join(",") ||
              "-",
            product_type: "私教",
            type: "pt",
          };
        }
      );
    },
    getcommodityList() {
      this.getlistImp(
        {
          api_name: "product.commodity.getlist",
          title: this.search.product_name,
          company_name: this.search.company_name,
        },
        (item) => {
          return {
            ...item,
            product_uuid: item.good_uuid,
            product_name: item.title,
            company_name: item.company_name,
            store_name:
              item.store_uuid_all?.map((item) => item.store_name).join(",") ||
              "-",
            product_type: "商品",
            type: "commodity",
          };
        }
      );
    },
    getactivityList() {
      this.getlistImp(
        {
          api_name: "product.activity.getlist",
          activity_title: this.search.product_name,
          company_name: this.search.company_name,
        },
        (item) => {
          return {
            ...item,
            product_uuid: item.activity_uuid,
            product_name: item.activity_title,
            company_name: item.company_name,
            store_name:
              item.store_uuid_all?.map((item) => item.store_name).join(",") ||
              "-",
            product_type: "活动",
            type: "activity",
          };
        }
      );
    },
    getcontestList() {
      this.getlistImp(
        {
          api_name: "product.contest.getlist",
          activity_title: this.search.product_name,
          company_name: this.search.company_name,
        },
        (item) => {
          return {
            ...item,
            product_uuid: item.activity_uuid,
            product_name: item.activity_title,
            company_name: item.company_name,
            store_name:
              item.store_uuid_all?.map((item) => item.store_name).join(",") ||
              "-",
            product_type: "赛事",
            type: "contest",
          };
        }
      );
    },
    handleTabsClick() {
      this.search = {};
      this.is_search();
    },
  },
};
</script>
<style scoped>
>>>.el-dialog__body {
  padding: 10px 20px;
}

.page-content-search {
  margin-top: 10px;
}
</style>
