<template>
  <div>
    <el-dialog
      v-loading="loading"
      :title="`${type === 'add' ? '添加标签' : '编辑标签'}`"
      :visible.sync="editPage"
      width="50%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-form size="medium" ref="form" :label-width="this.env.label_width">
          <el-col :span="24">
            <el-form-item label="标签名称">
              <el-input v-model="info.name" maxlength="5"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="关联品类">
              <MotionCategorySelect
                :motionCategoryList.sync="motion_category_two"
                :max="100000"
              />

              <MotioneffectSelect
                :motionffectList.sync="target_category_zero"
                :max="100000"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="标签描述">
              <el-input
                v-model="info.description"
                type="textarea"
                maxlength="20"
                show-word-limit
                rows="3"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="有效期">
              <el-date-picker
                style="width: 70%"
                v-model="searchtime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <el-row style="margin: 10px 0px">
        <el-button size="medium" type="primary" @click="save">确 定</el-button>
        <el-button size="medium" @click="editPage = false">取 消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import MotionCategorySelect from "@/components/MotionCategorySelect";
import MotioneffectSelect from "@/components/MotioneffectSelect";
export default {
  components: {
    MotionCategorySelect,
    MotioneffectSelect,
  },
  data() {
    return {
      loading: false,
      info: {
        name: null,
        description: null,
      },
      editPage: false,
      type: "",
      motion_category_two: [],
      target_category_zero: [],
      searchtime: [],
    };
  },
  methods: {
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    init(type, row) {
      this.type = type;
      if (type === "edit") {
        this.info = { ...row };
        this.searchtime = [row.start_time, row.end_time];
        this.motion_category_two = [...row.motion_category_two];
        this.target_category_zero = [...row.target_category_zero];
      } else {
        this.info = { name: null, description: null };
        this.searchtime = [];
        this.motion_category_two = [];
        this.target_category_zero = [];
      }

      this.editPage = true;
    },
    save() {
      let postdata = {
        api_name: "operate.tag.saves",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        state: 1,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.info);

      postdata.start_time = this.searchtime?.[0];
      postdata.end_time = this.searchtime?.[1];
      postdata.motion_category_two = this.motion_category_two.map(
        (item) => item.id
      );
      postdata.target_category_zero = this.target_category_zero.map(
        (item) => item.id
      );

      this.loading = true;

      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.editPage = false;
              this.$emit("refreshDataList");
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
  },
};
</script>
