<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>运营标签</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="标签名称">
                <el-input v-model="search.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="日期">
                <el-date-picker style="width: 70%" v-model="search.searchtime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="标签状态">
                <el-select v-model="search.state" placeholder="请选择类型" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="已禁用" :value="'3'"></el-option>
                  <el-option label="已启用" :value="'1'"></el-option>
                  <el-option label="已过期" :value="'2'"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">显示搜索
            </el-button>
            <el-button v-if="is_auth('operate.tag.saves')" icon="el-icon-plus" size="medium" type="primary"
              @click="addUpdata('add')">添加
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="ID" width="200"> </el-table-column>
        <el-table-column prop="name" label="标签名称" width="200">
        </el-table-column>
        <el-table-column prop="description" label="标签描述" width="200" />
        <el-table-column prop="store_all" label="关联门店" width="200">
          <template slot-scope="scope">
            <el-tag v-for="storeItem in scope.row.store_all" :key="storeItem.store_uuid" class="form-tag_colour-tag"
              size="mini" effect="dark">{{ storeItem.store_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="product_all" label="关联产品" min-width="200">
          <template slot-scope="scope">
            <el-tag :key="item.product_uuid" v-for="item in scope.row.product_all" class="form-tag_colour-tag"
              effect="dark" size="mini">
              {{ item.product_name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="motion_category_two" label="运动品类" min-width="200">
          <template slot-scope="scope">
            <el-tag :key="item.id" v-for="item in scope.row.motion_category_two" class="form-tag_colour-tag" effect="dark"
              size="mini">
              {{ item.name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="target_category_zero" label="运动效果" min-width="200">
          <template slot-scope="scope">
            <el-tag :key="item.id" v-for="item in scope.row.target_category_zero" class="form-tag_colour-tag"
              effect="dark" size="mini">
              {{ item.name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="有效时间" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.start_time }}至{{ scope.row.end_time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_at" label="添加时间" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.create_at }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="标签状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.state === 1">正常</el-tag>
            <el-tag size="mini" v-if="scope.row.state === 3" type="danger">禁用</el-tag>
            <el-tag size="mini" v-if="scope.row.state === 2" type="danger">已过期</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="330">
          <template slot-scope="scope">
            <el-button v-if="is_auth('operate.tag.setstore')" @click="addStore(scope.row)" type="primary" size="mini">关联门店
            </el-button>
            <el-button v-if="is_auth('operate.tag.setproduct')" @click="addProduct(scope.row)" type="primary"
              size="mini">关联产品
            </el-button>
            <el-button v-if="is_auth('operate.tag.saves')" @click="addUpdata('edit', scope.row)" type="primary"
              size="mini">编辑
            </el-button>
            <el-button v-if="scope.row.state === 3" @click="operation_state(scope.row, 1)" type="primary" size="mini">启用
            </el-button>
            <el-button v-if="scope.row.state === 1" @click="operation_state(scope.row, 3)" type="danger" size="mini">禁用
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
    </div>
    <AddOrUpdate v-if="visibleAddOrUpdate" ref="addOrUpdate" @refreshDataList="getlist()" />
    <AddOrProduct v-if="visibleAddOrProduct" ref="addOrProduct" @refreshDataList="getlist()" />
    <AddOrStore v-if="visibleAddOrStore" ref="addOrStore" @refreshDataList="getlist()" />
  </div>
</template>
<script>
import AddOrUpdate from "./label-add-or-update";
import AddOrProduct from "./label-add-or-product";
import AddOrStore from "./label-add-or-store";
export default {
  components: {
    AddOrUpdate,
    AddOrProduct,
    AddOrStore,
  },
  data() {
    return {
      page_name: "运营标签",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      visibleAddOrUpdate: false,
      visibleAddOrProduct: false,
      visibleAddOrStore: false,
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() { },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        name: "",
        state: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "operate.tag.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 操作
    isoperation(id = "", state = "") {
      let postdata = {
        api_name: "operate.tag.setstate",
        token: this.Tool.get_l_cache("token"),
        id,
        state,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 添加/编辑
    addUpdata(type, row) {
      this.visibleAddOrUpdate = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(type, row);
      });
    },
    // 添加门店
    addStore(row) {
      this.visibleAddOrStore = true;
      this.$nextTick(() => {
        this.$refs.addOrStore.init(row);
      });
    },
    // 添加产品
    addProduct(row) {
      this.visibleAddOrProduct = true;
      this.$nextTick(() => {
        this.$refs.addOrProduct.init(row);
      });
    },
    operation_state(item, state) {
      let tip = "";
      let options = {};
      // 启用
      if (state === 1) {
        tip = `启用【${item.name}】标签？`;
      }
      // 禁用
      if (state === 3) {
        tip = `禁用【${item.name}】标签？`;
      }

      // 弹出二次确认
      this.$confirm(tip, "确认信息", options)
        .then(() => {
          this.isoperation(item.id, state);
        })
        .catch(() => { });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

.form-tag_colour-div {
  min-height: 200px;
}
</style>
