<template>
  <div>
    <el-dialog title="关联门店" :visible.sync="editPage" width="70%" :close-on-press-escape="false"
      :close-on-click-modal="false">
      <!--内容-->
      <div class="">
        <!--搜索-->
        <div v-show="issearch" class="page-content-search">
          <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
            <el-row>
              <el-col :span="6">
                <el-form-item label="所属企业">
                  <el-input v-model="search.company_name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="门店名称">
                  <el-input v-model="search.store_name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="门店电话">
                  <el-input v-model="search.store_phone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="page-content-search-button">
              <el-col :span="24">
                <el-form-item>
                  <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                  </el-button>
                  <el-button icon="el-icon-circle-close" size="medium" @click="handleTabsClick">清空搜索</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <!--标题-->
        <el-row>
          <el-col :span="20">
            <h3></h3>
          </el-col>
          <el-col :span="4">
            <div>
              <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                @click="issearch = !issearch">隐藏搜索
              </el-button>
              <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                @click="issearch = !issearch">显示搜索
              </el-button>
            </div>
          </el-col>
        </el-row>
        <div style="height: 20px"></div>
        <!--列表-->
        <el-table size="medium" ref="multipleTable" @select="onselect" @select-all="onselect" border v-loading="loading"
          :data="tableData" style="width: 100%">
          <el-table-column type="selection" header-align="center" align="center" width="50">
          </el-table-column>
          <el-table-column prop="company_name" label="所属企业">
          </el-table-column>
          <el-table-column prop="store_name" label="门店名称">
          </el-table-column>
          <el-table-column prop="store_phone" label="门店电话">
          </el-table-column>
        </el-table>
        <div style="height: 20px"></div>
        <!--分页-->
        <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
          layout="prev, pager, next, total" :current-page.sync="page" :total="count">
        </el-pagination>
      </div>
      <el-row style="margin: 20px 0px">
        <el-button size="medium" type="primary" @click="save">确 定</el-button>
        <el-button size="medium" @click="editPage = false">取 消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      editPage: false,
      loading: false,
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      pageSize: 8,
      tableData: [], // 列表内容
      allSelections: [],
      item: {},
      isTrue: false,
    };
  },
  computed: {
    dataListSelections() {
      const selections = this.allSelections;
      const list = this.tableData;
      const result = [];

      list.forEach((item) => {
        selections.forEach((e) => {
          if (item.store_uuid === e.store_uuid) {
            result.push(item);
          }
        });
      });

      return result;
    },
  },

  watch: {
    dataListSelections: {
      handler(list) {
        if (!this.$refs.multipleTable) {
          return;
        }

        this.$nextTick(() => {
          list.forEach((item) => {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          });
        });
      },
    },
    editPage(v) {
      if (!v) {
        this.$emit("refreshDataList");
      }
    },
  },
  methods: {
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    init(row) {
      this.item = row;
      const store_all = [];
      row.store_all.forEach((item) => {
        if (!store_all.find((e) => item.store_uuid == e.store_uuid)) {
          store_all.push(item);
        }
      });
      this.allSelections = [...store_all];

      this.editPage = true;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    save() {
      this.editPage = false;
    },
    onselect(selection) {
      this.diffselection(selection);
      let store_all = [];
      this.allSelections.forEach((e) => {
        let item = {
          store_uuid: e.store_uuid,
          store_name: e.store_name,
        };
        store_all.push(item);
      });

      this.setStore(store_all);
    },
    handleTabsClick() {
      this.search = {};
      this.is_search();
    },
    setStore(store_all) {
      let postdata = {
        api_name: "operate.tag.setstore",
        token: this.Tool.get_l_cache("token"),
        store_all,
        id: this.item.id,
      };
      this.loading = true;

      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => { },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 获取列表
    async getlist() {
      let postdata = {
        api_name: "store.store.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, async (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.isTrue = false;
          this.isTrue = true;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    async getToggleRow(list) {
      new Promise((resolve) => {
        list.forEach((item, index) => {
          this.dataListSelections.forEach((e) => {
            if (item.store_uuid === e.store_uuid) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(item);
              });
            }
            if (list.length === index) {
              resolve(true);
            }
          });
        });
      });
    },

    diffselection(selection) {
      const removeList = this.tableData.filter(
        (item) => !selection.find((e) => item.store_uuid == e.store_uuid)
      );

      // 新增
      selection.forEach((item) => {
        if (!this.allSelections.find((e) => item.store_uuid == e.store_uuid)) {
          this.allSelections.push(item);
        }
      });

      // 删除
      removeList.forEach((item) => {
        const index = this.allSelections.findIndex(
          (e) => item.store_uuid == e.store_uuid
        );
        if (index != -1) {
          this.allSelections.splice(index, 1);
        }
      });
    },
  },
};
</script>
<style scoped>
>>>.el-dialog__body {
  padding: 10px 20px;
}

.page-content-search {
  margin-top: 10px;
}
</style>
